<div [class.mobile]="appService.isMobile$ | async" class="filters-container" data-cy="book-filters-modal">
  <div (click)="closeModal()" class="close-modal">
    <img alt="" src="assets/icon/blue-close.svg" />
  </div>

  <div class="title">{{ 'PWA_libraryFilters_title' | translate }}</div>
  <div class="description">{{ 'PWA_libraryFilters_ageRange_selection_header' | translate }}</div>

  <div class="grade-container">
    <div (click)="onSelectedGradeChange(null)" [class.active]="selectedGradeId === null" class="grade" data-cy="grade-option">
      {{ 'PWA_libraryFilters_ageRange_selection_all' | translate }}
    </div>
    @for (gradeFilter of gradeFilters; track $index) {
      <div data-cy="grade-option" class="grade" [class.active]="gradeFilter.id === selectedGradeId" (click)="onSelectedGradeChange(gradeFilter.id)">
        {{ backendTranslate(gradeFilter.name) }}
      </div>
    }
  </div>

  <div class="separator"></div>

  <div class="description">{{ 'PWA_libraryFilters_language_selection_header' | translate }}</div>

  <ion-radio-group (ionChange)="onSelectedLanguageChange($event)" [value]="selectedLanguageCode">
    <ion-radio [value]="null" data-cy="language-option" justify="start" label-placement="end" mode="md">
      {{ 'PWA_homeLibrary_filters_language' | translate }}
    </ion-radio>
    @for (languageFilter of languageFilters; track $index) {
      <ion-radio mode="md" justify="start" label-placement="end" [value]="languageFilter.code" data-cy="language-option">
        {{ languageFilter.name }}
      </ion-radio>
    }
  </ion-radio-group>

  <div class="separator"></div>

  <app-button
    (clicked)="applyFilter()"
    [bold]="true"
    [color]="'green'"
    [label]="'PWA_libraryFilters_CTA_applyFilters' | translate"
    [size]="'small'"
    [uppercase]="true"
    data-cy="filters-confirm-btn" />

  <div (click)="resetFilters()" class="reset-filters" data-cy="book-filters-reset-btn">
    {{ 'PWA_libraryFilters_CTA_link_resetFilters' | translate }}
    <img alt="" src="assets/icon/trash-outline-blue.svg" />
  </div>
</div>
