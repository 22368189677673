@for (book of books; track book.uuid) {
  <div [@fadeIn] #bookContainer class="book" [class.desktop]="(appService.isMobile$ | async) === false" data-cy="book-grid-book">
    @if (shelfType === ShelfType.Favorites) {
      <app-button
        data-cy="book-grid-like-btn"
        class="like"
        [shape]="'circle'"
        [icon]="'assets/icon/heart-red.svg'"
        [color]="'white'"
        [size]="'xxsmall'"
        (clicked)="like(book.uuid, false)" />
    }
    @defer (on viewport(bookContainer)) {
      <img
        (click)="goToBook(book.uuid, book.title)"
        src="{{ book | compressedImage }}"
        alt="{{ book?.title }}"
        [class.mobile]="appService.isMobile$ | async"
        class="cover" />
    } @error { }

    <div (click)="goToBook(book.uuid, book.title)"  class="book-title" [class.mobile]="appService.isMobile$ | async">
      <div class="title">
        {{ book.title }}
      </div>
    </div>
  </div>
}
